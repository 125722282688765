<template>
  <div>
    <div class="header">修改登录密码</div>
    <!-- 步骤条 -->
    <div class="steps">
      <a-steps style="width:778px" :current="step" size="default">
        <a-step title="修改密码" />
        <a-step title="操作完成" />
      </a-steps>
    </div>
    <!-- 第一步 -->
    <div class="step-common" v-if="step===0">
      <div>
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" @submit="handleSubmit">
          <a-form-item label="旧密码">
            <a-input
              placeholder="请填写旧密码"
              v-decorator="['usedPwd', { rules: [{ required: true, message: '请填写旧密码!' }] }]"
            />
          </a-form-item>
          <a-form-item label="新密码">
            <a-input
              type="password"
              placeholder="最少六位，至少包含大写字母/小写字母/数字/特殊字符"
              v-decorator="['newPwd', { rules: [{ required: true, message: '请输入新密码!' },{ validator: this.handlePwd }] }]"
            />
          </a-form-item>
          <a-form-item label="确认密码">
            <a-input
              type="password"
              placeholder="最少六位，至少包含大写字母/小写字母/数字/特殊字符"
              v-decorator="['oldpwdss', { rules: [{ required: true, message: '请输入旧密码!' },{ validator: this.handlePwds }] }]"
            />
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-button type="primary" html-type="submit">
              下一步
            </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <!-- 第二步 -->
    <div class="step-common" v-if="step===1">
      <div class="step-three">
        <div><a-icon style="color:rgb(11 219 168);font-size:40px" theme="filled" type="check-circle" /></div>
        <div style="margin-left:17px">
          <div class="stt-one">登录密码修改成功</div>
          <div class="stt-two">为了您的账户安全请妥善保管您的登录密码。</div>
          <div><a-button type="primary" @click="toPage">返回首页</a-button></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { USER_INFO } from "@/store/mutation-types";
import Vue from 'vue';
import {
  updateLoginPwd
} from '@/api/api'
export default {
  data () {
    return {
      step: 0,
      userInfo: '',
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
    }
  },
  created () {
    this.userInfo = Vue.ls.get(USER_INFO)
  },
  methods: {
    // 校验密码
    handlePwd (rule, value, callback) {
      const regex = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@_.#$%^&*? ])\S*$/;
      if (!regex.test(value)) {
        callback('密码格式不正确,最少六位,至少包含大写字母/小写字母/数字/特殊字符')
      }
      callback()
    },
    // 校验密码
    handlePwds (rule, value, callback) {
      let ff = this.form.getFieldsValue()
      const regex = /^\S*(?=\S{6,})(?=\S*\d)(?=\S*[A-Z])(?=\S*[a-z])(?=\S*[!@_.#$%^&*? ])\S*$/;
      if (!regex.test(value)) {
        callback('密码格式不正确,最少六位,至少包含大写字母/小写字母/数字/特殊字符')
      } else if (ff.newPwd!==ff.oldpwdss) {
        callback('两次密码输入不一致')
      }
      callback()
    },
    // 下一步
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          // console.log('Received values of form: ', values);
          updateLoginPwd(values).then(res => {
            if (res.success) {
              this.step = 1
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    // 回到首页
    toPage () {
      this.$router.push('/accountInfo')
    }
  }
}
</script>
<style scoped lang="less">
.header{
  color: #283039;
  font-size: 24px;
}
.steps{
  margin: 60px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}
.step-common{
  width: 500px;
  margin: 0 auto;
  padding: 50px 0 0 0;
}
.stc-oneo{
  display: flex;
  align-items: center;
  color: #293038;
  font-size: 14px;
  margin: 0 0 26px 0;
}
.stc-oneo-msg{
  position: relative;
  top: -20px;
  left: 90px;
}
.stc-oneo-opt{
  position: relative;
  top: 20px;
  left: 90px;
}
.stc-onet-opt{
  position: relative;
  top: 20px;
  left: 105px;
}
.step-three{
  display: flex;
  justify-content: center;
  .stt-one{
    font-size: 18px;
    color: #283039;
    font-weight: bold;
    margin: 5px 0 0 0;
  }
  .stt-two{
    margin: 24px 0 48px 0;
    color: #727682;
    font-size: 14px;
  }
}
</style>